import React, { useState } from "react";
import FooterText from "./FooterText";
import TaggrNoticeHeader from "../../component/common/TaggrNoticeHeader";
import success from '../../images/payment-success.svg'
import { useNavigate } from "react-router-dom";

function NoticePaymentSuccess() {
  const navigate = useNavigate()
  const urlSearchParams = new URLSearchParams(window.location.search);
  const paymentBy = urlSearchParams.get('payment_by');
  const navigate_to_dashboard = () => {
    console.log('test');
    const dashboardURL =
      process.env.REACT_APP_HOST === 'DEVELOPMENT'
        ? 'http://localhost:3001/admincitations'
        : 'https://dashboard.taggrsolutions.com/admincitations';
    window.location.href = dashboardURL;
  };
  return (
    <>
      <div className="taggr-notice-wrapper">
        <TaggrNoticeHeader />
        <div className="license-plate-container payment">
          
           <img src={success} />
           <h4 className='text-success'>Payment Successful</h4>
           <div>
              <button className='ReturnBtn' onClick={() => navigate('/pay')}>Go to Home</button>
            </div>

          <FooterText isFooterLinks={true} />
          {paymentBy && (
            <div>
              <button className='ReturnBtn' onClick={navigate_to_dashboard}>Return to Dashboard</button>
            </div>
           )}
        </div>
      </div>
    </>
  );
}

export default NoticePaymentSuccess;
