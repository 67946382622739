import React, { Suspense, lazy, useEffect } from 'react'
import { Routes, Route, useLocation } from "react-router-dom";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Header from '../component/common/Header'
import PaymentSuccess from "../utils/paymentsucess/Paymentsuccess"
import Paymenfailed from '../utils/paymentfailed/paymentfailed'

import "./App.scss";
import SearchLp from '../pages/TaggrNoticeFlow/SearchLp';
import TaggrNoticeHeader from '../component/common/TaggrNoticeBackground';
import FindYourNotice from '../pages/TaggrNoticeFlow/FindYourNotice';
import NoticeDetails from '../pages/TaggrNoticeFlow/NoticeDetails';
import NoticePayment from '../pages/TaggrNoticeFlow/NoticePayment';
import NoticePaymentSuccess from '../pages/TaggrNoticeFlow/NoticePaymentSuccess';
const CitationsSummary = lazy(() => import("../pages/CitationsSummary/CitationsSummary"));
const LookUp = lazy(() => import("../pages/LookUp/Lookup"));
const CitationsPayment = lazy(() => import("../pages/CitationsPayment/CitationsPayment"));
const Citations = lazy(() => import("../pages/Citations/Citations"));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const isHideHeader = [
    '/pay',
    '/pay/find-your-notice', 
    '/pay/notice-details',
    '/pay/notice-payment',
    '/pay/payment-success'
]

function Wrapper() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        //eslint-disable-next-line
    }, []);

    return (
        <>
            {!isHideHeader?.includes(location?.pathname?.replace(/\/$/, '')) ? <Header /> : <TaggrNoticeHeader /> }
            <Routes>
                {/* <Route
                    path="/citations"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <Citations />
                        </Suspense>
                    }
                /> */}
                <Route
                    path="/citations-payment"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <Elements stripe={stripePromise}>
                                <CitationsPayment />
                            </Elements>
                        </Suspense>
                    }
                />               

                <Route
                    path="/citations-summary"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <CitationsSummary />
                        </Suspense>
                    }
                />
                <Route
                    path="/"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <LookUp />
                        </Suspense>
                    }
                />
                <Route
                    path="paymentsuccess"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <PaymentSuccess />
                        </Suspense>
                    }
                />
                <Route
                    path="paymentfailed"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <Paymenfailed />
                        </Suspense>
                    }
                />
                <Route
                    path="/pay"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <SearchLp />
                        </Suspense>
                    }
                />
                <Route
                    path="/pay/find-your-notice"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <FindYourNotice />
                        </Suspense>
                    }
                />
                <Route
                    path="/pay/notice-details"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <NoticeDetails />
                        </Suspense>
                    }
                />
                <Route
                    path="/pay/notice-payment"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <Elements stripe={stripePromise}>
                                <NoticePayment />
                            </Elements>
                        </Suspense>
                    }
                />  
                <Route
                    path="/pay/payment-success"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <NoticePaymentSuccess />
                        </Suspense>
                    }
                />
            </Routes>
        </>
    )
}

export default Wrapper
