import React, { useEffect } from 'react';
import CloseNotification from '../../images/ic-close-btn.svg';
import success from '../../images/ic-success.svg';
import warning from '../../images/ic-warning.svg';
import error from '../../images/ic-error.svg';
import info from '../../images/ic-informational.svg';
import { capitalize } from '../../global/constants';

const images = {
  success,
  warning,
  error,
  info,
};

function BubbleAlert({ message, status, setState, actionText, onAction }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      setState(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, [setState]);

  return (
    <div className="BubbleAlertWrapper">
      <div className={`BubbleAlert BubbleAlert-${status}`}>
        <img src={images[status]} alt={status} className="AlertIcon" />
        <div className="AlertContent">
          {/* <h5>{capitalize(status)}!</h5> */}
          <p>
            {message === 'Failed to fetch'
              ? 'Something went wrong, please try again later!'
              : message}
          </p>
        </div>
        <button
          className="ActionButton"
          onClick={onAction || (() => setState(false))}
        >
          {actionText || 'Close'}
        </button>
      </div>
    </div>
  );
}

export default BubbleAlert;
