import React, { useEffect } from 'react'
import CloseNotification from "../../images/ic-close-btn.svg";
import success from "../../images/ic-success.svg";
import warning from "../../images/ic-warning.svg";
import error from "../../images/ic-error.svg";
import info from "../../images/ic-informational.svg";
import { capitalize } from '../../global/constants';

const images = {
    success,
    warning,
    error,
    info
}


function Alert({ message, status, setState }) {

    useEffect(() => {
        setTimeout(() => {
            setState(false)
        }, 2000)
    }, [])
    return (
        <>
            <div className="NotificationButtonWrapper ">
                <button className={'Notification' + [status]}>
                    <div className="Flexsec">
                        <img src={images[status]} className="SuccessIcon" />
                        <h5>{capitalize(status)}!</h5>
                        <span>
                            <img src={CloseNotification}
                                onClick={
                                    () => setState(false)
                                } />
                        </span>
                    </div>
                    <p>
                        {message === "Failed to fetch" ? "Something went wrong, please try again later!" : message}
                    </p>
                </button>
            </div>

        </>
    )
}


export default Alert



// <button className="NotificationWarning">
// <div className="Flexsec">
//     <img src={IcWarning} className="SuccessIcon" />
//     <h5>Warning </h5>
//     <span>
//         <img src={CloseNotification} />
//     </span>
// </div>
// <p>
//     Lorem Ipsum is simply dummy text of the printing and typesetting
//     industry.Lorem Ipsum has been.
// </p>
// </button>

// <button className="NotificationError">
// <div className="Flexsec">
//     <img src={IcError} className="SuccessIcon" />
//     <h5>Error</h5>
//     <span>
//         <img src={CloseNotification} />
//     </span>
// </div>
// <p>
//     Lorem Ipsum is simply dummy text of the printing and typesetting
//     industry.Lorem Ipsum has been.
// </p>
// </button>

// <button className="NotificationInformational">
// <div className="Flexsec">
//     <img src={IcInformational} className="SuccessIcon" />
//     <h5>Informational</h5>
//     <span>
//         <img src={CloseNotification} />
//     </span>
// </div>
// <p>
//     Lorem Ipsum is simply dummy text of the printing and typesetting
//     industry.Lorem Ipsum has been.
// </p>
// </button>