export const delayLoading = (state, ms) => setTimeout(() => state(false), ms ? ms : 2000);
export const storeDelayLoading = (dispatch, state, ms) => setTimeout(() => dispatch(state(false)), ms ? ms : 2000);
export const capitalize = s => (s && s[0]?.toUpperCase() + s?.slice(1)) || ""

export const Two_decimals = (n) => parseFloat(n.toFixed(2))

export const formatPhoneNumber = (value) => {
  const phoneNumber = value.replace(/[^\d]/g, "");

  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength <= 3) {
    return phoneNumber;
  } else if (phoneNumberLength <= 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else if (phoneNumberLength <= 10) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  } else {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)} ${phoneNumber.slice(10)}`;
  }
};