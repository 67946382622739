import React, { useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TaggrNoticeHeader from "../../component/common/TaggrNoticeHeader";
import Alert from "../../utils/alert/Alert";
import { useStripe, useElements, CardElement, AddressElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { formatPhoneNumber, Two_decimals } from "../../global/constants";
import IcBack from "../../images/ic-back-arrow.svg";
import IcInfo from "../../images/ic-personal-info.svg";
import IcFee from "../../images/ic-fee.svg";
import IcCard from "../../images/ic-card.svg";
import SignaturePad from "../../component/SignaturePad/SignaturePad";
import "../CitationsPayment/style.scss"
import BubbleAlert from "../../utils/alert/BubbleAlert";
// /CitationsPayment/style.scss";

// Initial state for the form
const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
};

export default function NoticePayment() {
  const location = useLocation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const search = location?.search
  const notice = location?.state?.notice || null;
  console.log(notice, "<pay notice==")
  const data = notice?.break_down_amount
  const dropBoxDescription = notice?.lotdata?.dropboxLocationDescription
  const payment_by = new URLSearchParams(search).get('payment_by')
  const is_service_fee = ["admin", "call_center"].includes(payment_by);
 const [showDiv, setShowDiv] = useState(false)
  const [alert, setAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({ message: "", status: "" });
  const [signatureURL, setSignatureURL] = useState("");
  const [confirm_email, setConfirmEmail] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [state, setState] = useReducer((prev, next) => ({ ...prev, ...next }), initialState);

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    confirm_email: "",
    address: "",
    signature: "",
  });

  useEffect(() => {
    if (!notice) {
      setAlert(true);
      setAlertObj({ message: "Citation does not exist!", status: "info" });
      setTimeout(() => navigate("/pay"), 500);
    }
  }, [notice, navigate]);

  // Validation function
  const validateForm = (billing_address_data) => {
    const newErrors = {
      first_name: state.first_name.trim() === "" ? "Please enter first name" : "",
      last_name: state.last_name.trim() === "" ? "Please enter last name" : "",
      email: state.email.trim() === "" ? "Please enter email" : "",
      phone_number: state.phone_number.trim() === "" ? "Please enter phone number" : "",
      confirm_email: confirm_email.toLowerCase() !== state.email.toLowerCase() ? "Email and confirm email do not match" : "",
      address: billing_address_data?.address?.line1?.trim() === "" || billing_address_data?.address?.postal_code?.trim() === "" || billing_address_data?.address?.city?.trim() === "" || billing_address_data?.address?.state?.trim() === "" ? "Please complete address fields" : "",
      signature: signatureURL?.length <= 0 ? "Signature required" : ""
    };

    setErrors(newErrors);
    return Object.values(newErrors).every(error => error === "");
  };


  const handlePayment = async () => {
    const billing_address_data = (await elements.getElement(AddressElement).getValue()).value;
    setIsSubmit(true);

    const handleError = (message) => {
      setAlert(true);
      setAlertObj({ message, status: "error" });
    };

    if (!validateForm(billing_address_data)) {
      setIsSubmit(false);
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: billing_address_data,
      });

      if (error) {
        handleError(error.message);
        setIsSubmit(false);
        return;
      }

      const { id } = paymentMethod;
      try {
        let paymentUrl = !notice?.isNoticeTagVehicle 
        ? `${process.env.REACT_APP_BACKEND_URL}/taggr-notices/payment${payment_by ? `?payment_by=${payment_by}` : ""}`
        : `${process.env.REACT_APP_BACKEND_URL}/payment${payment_by ? `?payment_by=${payment_by}` : ""}`
        const response = await axios.post(paymentUrl, {
          citation_id: notice?._id,
          source: id,
          lateFee: notice?.lateFee,
          violator_data: {
            ...state,
            billing_address: billing_address_data?.address?.line1,
            billing_zip_code: billing_address_data?.address?.postal_code,
            email: state.email.toLowerCase(),
            signatureURL,
          },
        });

        if (response?.data?.success) {
          let navigationTo = !notice?.isNoticeTagVehicle 
          ? '/pay/payment-success'
          : `/paymentsuccess?message=${response?.data?.message}${payment_by ? `&payment_by=${payment_by}` : ""}${dropBoxDescription ? `&Location=${dropBoxDescription} `: "" }`
          navigate(navigationTo)
        } else {
          handleError(response?.data?.message || 'Unknown error occurred during payment processing.');
        }
      } catch (error) {
        handleError(error?.message || 'An unexpected error occurred.');
      }
    } catch (error) {
      handleError(error?.message || 'An unexpected error occurred.');
    }

    setIsSubmit(false);
  };

  const clearErrors = () => {
    setErrors({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      confirm_email: "",
      address: "",
      signature: "",
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = name === 'phone_number' ? formatPhoneNumber(value).replace(/[^\d]/g, '') : value;
    setState({ [name]: formattedValue });

    // Clear the error states if input is not empty
    if (value !== '') {
      clearErrors();
    }
  };

  const phoneNumberFormatter = () => {
    const input = document.getElementById('phone-number');
    const isAutofilled = input.getAttribute('data-autofilled') === 'true';

    if (!isAutofilled) {
      const formattedInputValue = formatPhoneNumber(input.value);
      input.value = formattedInputValue;
    }

    setTimeout(() => {
      const formattedInputValue = formatPhoneNumber(input.value);
      input.value = formattedInputValue;
      input.setAttribute('data-autofilled', 'false');
    }, 500);
  }

  return (
    <>
      {alert && <BubbleAlert message={alertObj?.message} status={alertObj?.status} setState={setAlert} />}
      <div className="taggr-notice-wrapper">
        <TaggrNoticeHeader />
        <div className="find-notice-container">
        <div className="CitationsWapper PaymentWrap custom-padding" >
        <div className="InformWrapper">
            <div>
            <h4 className="FormListTitle">
                <img src={IcInfo} /> Personal Information
              </h4>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="InputLabels">First Name</label>
                    <input type="text" className="InputStyles"
                      name="first_name"
                      onChange={onChange}
                      onClick={clearErrors}
                    />
                     {errors?.first_name && <div className="Error">{errors?.first_name}</div>}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="InputLabels">Last Name</label>
                    <input type="text" className="InputStyles"
                      name="last_name"
                      onChange={onChange}
                      onClick={clearErrors}
                    />
                     {errors?.last_name && <div className="Error">{errors?.last_name}</div>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="InputLabels">Email</label>
                    <input type="text" className="InputStyles"
                      name="email"
                      onClick={clearErrors}
                      onChange={onChange}
                    />
                    {errors?.email && <div className="Error">{errors?.email}</div>}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="InputLabels">Confirm Email</label>
                    <input type="text"
                      className="InputStyles"
                      onClick={clearErrors}
                      onChange={e => setConfirmEmail(e?.target?.value)}
                    />
                    {errors?.confirm_email && <div className="Error">{errors?.confirm_email}</div>}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="InputLabels">Phone number</label>
                    <input
                      type="tel"
                      className="InputStyles"
                      name="phone_number"
                      id="phone-number"
                      onKeyDown={phoneNumberFormatter}
                      maxLength="14"
                      onClick={clearErrors}
                      onChange={onChange}
                      required
                    />
                    {errors?.phone_number && <div className="Error">{errors?.phone_number}</div>}
                  </div>
                </div>
              </div>


              <h4 className="FormListTitle">
                <img src={IcFee} /> Fee breakdown
              </h4>
              <div className="row">
                <div className="col-lg-12">
                  <div className="PaymentBox">
                    <h5>Total payment due</h5>
                    <p><strong>${is_service_fee ? (parseFloat(notice?.amountToPay) + 10).toFixed(2) : parseFloat(notice?.amountToPay).toFixed(2)}</strong> (Taxes & fees included)</p>
                  </div>
                </div>
              </div>
             
              <div className="row">
                <div className="col-lg-12 text-end">
                  <button className="ShowDeatils" onClick={() => setShowDiv(!showDiv)}>Show details</button>
                </div>
                {
                  showDiv &&
                  <div className="col-lg-12">

                    <div className="ShowDetailsWrap">
                      <div className="RowBorder Flex">
                        <h4>Violation Fee</h4>
                        <h5>${parseFloat(notice?.break_down_amount?.offence_fees)?.toFixed(2)}</h5>
                      </div>

                      <div className="RowBorder">
                        <ul className="ListTags">
                          <li>{notice?.offence?.offenceName}</li>
                        </ul>
                      </div>

                      <div className="RowBorder Flex">
                        <h4>Late Fee</h4>
                        <h5>${parseFloat(notice?.lateFee)?.toFixed(2)}</h5>
                      </div>
      
                      <div className="RowBorder Flex">
                        {/* <h4>Stripe Fee (Violation Fee)</h4> */}
                        <h4>Processing Fee</h4>
                        <h5>${Two_decimals((notice?.break_down_amount?.stripe_fees_offence || 0) + (notice?.break_down_amount?.dock_fees || 0))}</h5>
                      </div>
                      {is_service_fee && <div className="RowBorder Flex">  
                         <h4>Call Center fee</h4>
                         <h5>10</h5>
                      </div>}

                    </div>
                  </div>
                }
              </div>

              <h4 className="FormListTitle">
                Billing Address
              </h4>

              <AddressElement options={{
                mode: "billing"
                }}
                onChange={(e) => setErrors({...errors, address: !e?.complete})}
              />  
              <h4 className="FormListTitle">
                <img src={IcCard} /> Card information
              </h4>
              <CardElement options={{
                hidePostalCode: true,
              }}
                className="citation-payment"
              />
              <SignaturePad 
                onSaveSignature={setSignatureURL}
                onClearSignature={()=> setSignatureURL("")}
                isSubmit={isSubmit}
                title="Please Sign to Authorize Your Payment"
              />
              <p>
                By tapping Pay Notice you agree to Taggr Solutions LLC <a href="https://taggrsolutions.com/terms-of-use" target="_blank">Terms of Use</a> and <a href="https://taggrsolutions.com/privacy-policy" target="_blank">Privacy Policy</a>
              </p>

              <div className="text-center mt-5">
                <button className="DarkBtn" disabled={isSubmit || errors?.address} onClick={handlePayment}>
                  {isSubmit ? "Processing..." : 'Pay Notice'}
                </button>
              </div>

            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
