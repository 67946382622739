import React, { useEffect, useState } from "react";
import FooterText from "./FooterText";
import TaggrNoticeHeader from "../../component/common/TaggrNoticeHeader";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../utils/alert/Alert";
import moment from "moment-timezone";
import { Two_decimals } from "../../global/constants";
import BubbleAlert from "../../utils/alert/BubbleAlert";

const NoticeDetails = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const tz = moment.tz.guess()
  const notice = location?.state?.notice || null
  const [isAlert, setAlert] = useState(false)
  const [alertObj, setAlertObj] = useState({ message: "", status: "" })
console.info(location?.state, "<==state0")
  useEffect(() => {
    if (!notice) {
      setAlert(true)
      setAlertObj({ message: "Citation does not exists!", status: 'info' })
      const tm_out = setTimeout(() => {
        navigate('/pay')
        clearTimeout(tm_out)
      }, 500)
    }
  }, [])

  const isNoticeTagVehicle = notice?.citation_type === "tag_vehicle"
  const dueToday = Two_decimals(notice?.amount || 0);
  const after7Days = Two_decimals(dueToday + 20);
  const after30Days = Two_decimals(after7Days + 20);

  const createdAt = moment(notice.createdAt);
  const now = moment();
  const daysSinceCreated = now.diff(createdAt, 'days');
  const daysLeftFor7Days = Math.max(7 - daysSinceCreated, 0);
  const daysLeftFor30Days = Math.max(30 - daysSinceCreated, 0);

  const isPast7Days = daysSinceCreated > 7;
  const isPast30Days = daysSinceCreated > 30;

  let amountToPay = dueToday;
  if (daysSinceCreated > 30) {
    amountToPay = after30Days;
  } else if (daysSinceCreated > 7) {
    amountToPay = after7Days;
  }
  if (isNoticeTagVehicle) {
    amountToPay = dueToday
  }

  return (
    <>
      {
        isAlert && <BubbleAlert message={alertObj?.message} status={alertObj?.status} setState={setAlert} />
      }
      <div className="taggr-notice-wrapper">
        <TaggrNoticeHeader />
        <div className="notice-container">
          <div className="card">
            <div className="card-title text-center"> Notice Details</div>

            <div className="card-details">
              <div className="detail-row">
                <i className="icon-license-plate"></i>
                <div>
                  <span className="detail-label"> License Plate: </span>
                  <span className="detail-value">{notice?.vehicle?.licensePlate || 'N/A'}</span>
                </div>
              </div>
              {isNoticeTagVehicle && (
                <div className="detail-row">
                  <i className="icon-tag"></i>
                  <div>
                    <span className="detail-label"> Tag Serial No: </span>
                    <span className="detail-value">{notice?.tireTagSerialNumber || 'N/A'}</span>
                  </div>
                </div>
              )}
              <div className="detail-row">
                <i className="icon-violation"></i>
                <div>
                  <span className="detail-label"> Violation: </span>
                  <span className="detail-value">{notice?.offence?.offenceName || 'N/A'}</span>
                </div>
              </div>
              <div className="detail-row">
                <i className="icon-date"></i>
                <div>
                  <span className="detail-label"> Date: </span>
                  <span className="detail-value">
                    {moment(notice?.createdAt)?.tz(tz)?.format("MM-DD-YYYY") || 'N/A'}
                  </span>
                </div>
              </div>
              <div className="detail-row">
                <i className="icon-time"></i>
                <div>
                  <span className="detail-label"> Time: </span>
                  <span className="detail-value">
                    {moment(notice?.createdAt)?.tz(tz)?.format("HH:mm A") || 'N/A'}
                  </span>
                </div>
              </div>
              <div className="detail-row">
                <i className="icon-location"></i>
                <div>
                  <span className="detail-label"> Location: </span>
                  <span className="detail-value">{notice?.lotdata?.address || 'N/A'}</span>
                </div>
              </div>
            </div>


            <div className="section-title">Vehicle Images</div>
            <div className="row">
              <div className="col-6">
                <img
                  alt="Vehicle Dashboard image"
                  className="img-fluid"
                  height="150"
                  src={isNoticeTagVehicle ? notice?.images?.windowDecalImage : notice?.images?.dashboard_image}
                  width="150"
                />
              </div>
              <div className="col-6">
                <img
                  alt="Vehicle License Plate image"
                  className="img-fluid"
                  height="150"
                  src={isNoticeTagVehicle ? notice?.images?.licensePlateImage : notice?.images?.license_plate_image}
                  width="150"
                />
              </div>
            </div>

           {!isNoticeTagVehicle && <div className="card-payment-info">
              <p style={{ textDecoration: isPast7Days ? 'line-through' : 'none' }}>
              Due Today: ${dueToday}
              </p>
              <p
               className={isPast7Days ? "" : "text-danger"}
               style={{ textDecoration: isPast30Days ? 'line-through' : 'none' }}>
                 After 7 days: ${after7Days} (days left {daysLeftFor7Days})
              </p>
              <p className="text-danger">After 30 days: ${after30Days} (days left {daysLeftFor30Days})</p>
            </div>}

            <div className="d-flex justify-content-around mt-3">
              <button className="btn btn-pay" onClick={() => navigate('/pay/notice-payment', {state: {notice: {...notice, amountToPay, lateFee: amountToPay - notice?.amount, isNoticeTagVehicle }}})}>Pay ${amountToPay}</button>
              <button className="btn btn-dispute" onClick={window["openChat"]}>Dispute</button>
            </div>

            <FooterText isFooterLinks={true} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeDetails;
