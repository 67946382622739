import React, { useState } from "react";
import FooterText from "./FooterText";
import TaggrNoticeHeader from "../../component/common/TaggrNoticeHeader";
import { useNavigate } from "react-router-dom";
import Alert from "../../utils/alert/Alert";
import * as api from "../../api"
import BubbleAlert from "../../utils/alert/BubbleAlert";

function SearchLp() {
  const navigate = useNavigate()
  const [licensePlate, setLicensePlate] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [error, setError] = useState("");
  const [isAlert, setAlert] = useState(false)
  const [alertObj, setAlertObj] = useState({ message: "", status: "" })

  const handleLicensePlateChange = (event) => {
    setLicensePlate(event.target?.value?.toUpperCase());
    setError(""); // Clear error when user starts typing
  };

  const handleSerialNumberChange = (event) => {
    setSerialNumber(event.target.value);
    setError(""); // Clear error when user starts typing
  };

  const handleSearch = async () => {
    try {
      // Validate inputs
      if (!licensePlate && !serialNumber) {
        setError("Please enter either a License Plate or a Serial Number.");
        // setError("Please enter a License Plate");
        return;
      }

      if (licensePlate && licensePlate?.trim().length < 2) {
        setError("License Plate must be at least 2 characters long");
        return;
      }

      if (serialNumber && serialNumber?.trim().length < 3) {
        setError("Serial Number must be at least 3 characters long");
        return;
      }

      const { data } = await api.getTaggrNotices(licensePlate, serialNumber)
      if (!data?.success || data?.citation?.length <= 0) {
        setAlert(true)
        setAlertObj({ message: data?.message, status: 'error' })
        return
      }
      // Proceed with the search
      console.log("Searching with the following inputs:");
      console.log("License Plate:", licensePlate);
      console.log("Serial Number:", serialNumber);
      const isSingleNotice = data?.citation?.length === 1
      console.log(isSingleNotice, "<==issinglenotice")
      navigate(
        isSingleNotice
          ? `/pay/notice-details`
          : `/pay/find-your-notice`,
        { state: 
          isSingleNotice
          ? { notice: data?.citation?.[0]}
          : { notices: data?.citation}}
      )
    } catch (error) {
      setAlert(true)
      setAlertObj({ message: error?.message, status: 'error' })
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch()
    }
  };

  return (
    <>
      {
        isAlert && <BubbleAlert message={alertObj?.message} status={alertObj?.status} setState={setAlert} />
      }
      <div className="taggr-notice-wrapper">
        <TaggrNoticeHeader />
        <div className="license-plate-container">
          <h1 className="title">Enter License Plate</h1>
          <input
            type="text"
            className="form-control input-field"
            placeholder="License Plate..."
            value={licensePlate?.toUpperCase()}
            onChange={handleLicensePlateChange}
            onKeyDown={handleKeyPress}
          />
          <h2 className="subtitle">and/or</h2>
          <h1 className="title">Tire Tag Serial Number</h1>
          <input
            type="text"
            className="form-control input-field"
            placeholder="Serial Number of Tag on Wheel..."
            value={serialNumber}
            onChange={handleSerialNumberChange}
             onKeyDown={handleKeyPress}
          />
          <p className="description warning">
            <strong>If booted, you can enter one or both of the above</strong>
          </p>
          {error && (
            <div className="error-message mt-3 mb-2">
              ‼️ {error}
            </div>
          )}
          <button
            className="btn btn-dark submit-btn"
            onClick={handleSearch}
          >
            Search
          </button>
          <FooterText />
        </div>
      </div>
    </>
  );
}

export default SearchLp;
