import React, { useEffect, useState } from "react";
import TaggrNoticeHeader from "../../component/common/TaggrNoticeHeader";
import FooterText from "./FooterText";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../utils/alert/Alert";
import moment from "moment-timezone";
import BubbleAlert from "../../utils/alert/BubbleAlert";

const FindYourNotice = () => {
  const [licensePlate, setLicensePlate] = useState(""); // To track the input value
  const [error, setError] = useState(""); // To track any errors
  const navigate = useNavigate()
  const location = useLocation();
  const tz = moment.tz.guess()
  console.log(location.state, "<==state")
  const notices = location?.state?.notices || []
  const [filteredNotices, setFilteredNotices] = useState(notices); 
  const [isAlert, setAlert] = useState(false)
  const [alertObj, setAlertObj] = useState({ message: "", status: "" })

  useEffect(() => {
    if (!notices || notices?.length <= 0) {
      setAlert(true)
      setAlertObj({ message: "Citation does not exists!", status: 'info' })
      const tm_out = setTimeout(() => {
        navigate('/pay')
        clearTimeout(tm_out)
      }, 500)
    }
  }, [notices, navigate]);

  useEffect(() => {
    const filtered = notices.filter((notice) =>
      notice?.vehicle?.licensePlate
        ?.toLowerCase()
        .includes(licensePlate.toLowerCase())
    );
    setFilteredNotices(filtered);
    setError(
      licensePlate && filtered.length === 0
        ? "No notices found for this license plate"
        : ""
    );
  }, [licensePlate, notices]);


  return (
    <>
      {
        isAlert && <BubbleAlert message={alertObj?.message} status={alertObj?.status} setState={setAlert} />
      }
      <div className="taggr-notice-wrapper">
        <TaggrNoticeHeader />
        <div className="find-notice-container">
          <div className="find-notice-header">Find Your Notice</div>

          {/* License Plate Input */}
          <div className="find-notice-input-wrapper">
            <input
              type="text"
              className="find-notice-input"
              placeholder="Enter License Plate"
              value={licensePlate}
              onChange={(e) => setLicensePlate(e.target.value)}
            />
            {error && <div className="error-message">{error}</div>}
          </div>

          {/* Scrollable Notices Section */}
          <div className="find-notice-notices">
            {filteredNotices?.map((notice, index) => (
              <div key={index} className="find-notice-item">
                <div className="find-notice-details">
                  <p className="notice-detail">LP: {notice?.vehicle?.licensePlate}</p>
                  <p className="notice-detail">Violation: {notice?.offence?.offenceName}</p>
                  <p className="notice-detail">Date: {moment(notice?.createdAt)?.tz(tz)?.format("MM-DD-YYYY")}</p>
                  <p className="notice-detail">Time: {moment(notice?.createdAt)?.tz(tz)?.format("HH:mm A")}</p>
                  <p className="notice-detail">Location: {notice?.lotdata?.address}</p>
                </div>
                <div className="find-notice-separator" />
                <button className="find-notice-view-btn" onClick={() => navigate('/pay/notice-details', {state: {notice}})}>View</button>
              </div>
            ))}
          </div>

          {/* Footer */}
          <FooterText isFooterLinks={true} />
        </div>
      </div>
    </>
  );
};

export default FindYourNotice;
