import React from 'react'

export default function FooterText({isFooterLinks = false}) {
  return (
    <div className="find-notice-footer">
      <p className="find-notice-footer-text">
        Taggr is a 3rd party parking enforcement solution that issues notices
        on behalf of property owners and managers. It’s important to note that
        Taggr enforces locations across the country and unpaid notices from one
        location may cause additional inconvenience at another.
      </p>
      {isFooterLinks && <>
        <a href="https://www.taggrsolutions.com/terms-of-use/" target='_blank' className="find-notice-footer-link">Terms of Use</a>
        <a href="https://www.taggrsolutions.com/privacy-policy/" target='_blank' className="find-notice-footer-link">Privacy Policy</a>
      </>}
    </div>
  )
}
