
import React, { useEffect, useState } from "react";
import brandLogo from "../../images/brand-logo.svg";
import "./fullNavStyles.scss"

const TaggrNoticeHeader = () => {

  return (
    <>
      <div className="taggrNoticeLogo">
        <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
        <h6 className="BrandTagline">Enforcement Simplified </h6>
      </div>
    </>
  );
}

export default TaggrNoticeHeader