import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./style.scss";

const SignaturePad = ({ title, onSaveSignature, onClearSignature, isSubmit }) => {
  const sigPadRef = useRef(null);
  const [error, setError] = useState(null);
  const [signatureURL, setSignatureURL] = useState("");

  const clearSignature = () => {
    if(!isSubmit){
      sigPadRef?.current?.clear();
      onClearSignature()
      setSignatureURL("");
      setError(null);
    }
  };

  const saveSignature = () => {
    if (sigPadRef?.current?.isEmpty()) {
      setError("Signature pad is empty. Please provide a signature.");
      return;
    }
    try {
      const dataURL = sigPadRef?.current?.toDataURL("image/png");
      setSignatureURL(dataURL);
      onSaveSignature(dataURL);
      setError(null);
    } catch (e) {
      setError("An error occurred while saving the signature.");
    }
  };

  return (
    <div className="signature-container">
      <h2 className="signature-title">{title}</h2>
      {!signatureURL && (
        <>
          <SignatureCanvas
            ref={sigPadRef}
            penColor="black"
            canvasProps={{
              width: 500,
              height: 200,
              className: "sigCanvas",
            }}
          />
          {error && <p className="error-message">{error}</p>}
          <div className="button-group">
            <button className="black-button" onClick={saveSignature}>
              Save Signature
            </button>
            <button className="black-button" onClick={clearSignature}>
              Clear Signature
            </button>
          </div>
        </>
      )}
      {signatureURL && (
        <div className="saved-signature">
          {/* <h3>Saved Signature:</h3> */}
          <img
            src={signatureURL}
            alt="Saved signature"
            className="signature-image"
          />
          <div className="button-group">
            <button className="black-button" onClick={clearSignature}>
              Clear Signature
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignaturePad;
